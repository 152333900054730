@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-font-smoothing: antialiased;
}

body {
  overflow-y: scroll;
}

.Page {
  min-height: 100vh;
}
